import React from "react"
import tw, { styled } from "twin.macro"

import Link from "../components/Link"

import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Container = styled.main`
  ${tw`flex flex-col py-34 px-6 md:px-22 xl:px-44 md:py-64 overflow-hidden relative`}
`

const Title = styled.h1`
  ${tw`font-semibold text-subtitle md:text-hero text-default font-header leading-normal`}
`

const Subtitle = styled.h2`
  ${tw`text-lg md:text-2xl text-default font-header mt-6 mb-14 md:mt-2.5 md:mb-20 leading-normal`}
`

const HeroButton = styled.div`
  ${tw`w-3/4`}
`

const Icon = styled(FontAwesomeIcon)`
  ${tw`ml-3`}

  vertical-align: middle;
`

const NotFound = () => (
  <Container>
    <Title>Page not found</Title>
    <Subtitle>Whoops! The page you are looking for does not exist.</Subtitle>
    <HeroButton>
      <Link
        text={
          <>
            <span>Go home</span>
            <Icon icon={faArrowRight} />
          </>
        }
        url="/"
      />
    </HeroButton>
  </Container>
)

export default NotFound
